import { 
    Container,
    Row,
    Col,
    Image
} from 'react-bootstrap';
import ScrollToHashElement from '../utils/scrollToHashElement';

const Services = () => {
    return (
        <Container className='navbar-offset service-page px-0 w-100'>
            <Row className='w-100 d-flex align-items-center'>
                <Col className='text-center pt-5'>
                    UPDATE COMING SOON
                    <p>(posted 12/21/24)</p>
                </Col>
            </Row>
        </Container>
    )
}

export default Services;